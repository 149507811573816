import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PickList } from '../../models/Picklist';
import LanguageUtils from '../../utils/LanguageUtils';
import PicklistEditorPortal from '../picklist-builder/PicklistEditorPortal';
import PicklistItemEditor from '../picklist-builder/PicklistItemEditor';
import { BreadCrumbBackButton } from '../shared/breadcumb/BreadCrumbBackButton';
import { Heading, HeadingSize } from '../shared/text/Heading';

type OrgPickListItemsProps = {
  picklist: PickList;
  onClose: () => void;
  onItemsChange: () => void;
};

const OrgPickListItems: FC<OrgPickListItemsProps> = (props) => {
  const { picklist, onClose, onItemsChange } = props;
  const { t } = useTranslation('organisation');

  return (
    <div className="flex flex-col pt-6">
      <div>
        <div className="flex items-center gap-4">
          <BreadCrumbBackButton onClick={onClose} />{' '}
          <Heading size={HeadingSize.H2}>{LanguageUtils.getTranslation('name', picklist.translations)}</Heading>
          <div className="flex-grow"></div>
          <div className="flex justify-between gap-4">
            <div id="picklist-detail-button-portal"></div>
          </div>
        </div>

        {!!LanguageUtils.getTranslation('description', picklist.translations) && (
          <>
            <div className="pt-2 text-black">{t('picklists.description')}</div>
            <div className="text-dpm-12">{LanguageUtils.getTranslation('description', picklist.translations)}</div>
          </>
        )}
      </div>
      <div className="-mx-6 h-full p-8 pt-4">
        {picklist && <PicklistItemEditor picklist={picklist} onItemChanged={onItemsChange} buttonWrapper={PicklistEditorPortal} />}
      </div>
    </div>
  );
};

export default OrgPickListItems;
